import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  Content,
  Hero,
  SidebarArticles,
  SimilarStories,
} from './components';

// eslint-disable-next-line react/prop-types
const BlogArticle2 = ({ id }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [post, setPost] = useState(null);

  useEffect(() => {
    // Assuming you have an endpoint to fetch a post by ID
    fetch(`https://cms.cognijiwa.ai/items/blogs?fields=*.*&filter={ "id": { "_eq": "${id}" }}`)
      .then(response => response.json())
      .then(data => setPost(data))
      .catch(error => console.error('Fetching post data error:', error));
  }, [id]);

  if (!post) return <p>Loading...</p>;

  return (
    <Main colorInvert={true}>
      <Box>
        <Hero />
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Content />
            </Grid>
            <Grid item xs={12} md={4}>
              {isMd ? (
                <Box marginBottom={4}>
                  <SidebarArticles />
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.alternate.main}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
      <Box bgcolor={'alternate.main'}>
        <Container>
          <SimilarStories />
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </Main>
  );
};

export default BlogArticle2;
